<template>
  <div class="w-full">
    <div
      class="menu_element_block flex align-middle items-center pl-4"
      :class="{'activeR': sendPath || activeD}"
      @click="openDrag"
    >
      <icon
        :data="icon"
        height="25"
        width="25"
        class="menu_element_icon"
        color="#8E8EA9"
        original
      />
      <div class="menu_element ml-4 text-c18 w-3/4 text-left">
        {{ menu }}
      </div>
      <icon
        v-if="!activeD"
        :data="icons.right"
        height="25"
        width="25"
        class="menu_element_icon"
        original
      />
      <icon
        v-if="activeD"
        :data="icons.down"
        height="20"
        width="20"
        class="menu_element_icon"
        color="#fff"
        original
      />
    </div>

    <div
      v-if="activeD"
      class="mt-6 pl-12"
    >
      <div
        v-for="(item, index) in allItem"
        :key="index"
        class="text-left text-c16 text cole mt-4 pl-2 cursor-pointer"
        :class="{'font-c6 change': item.toLowerCase() === position}"
        @click="selected(item)"
      >
        {{ item.substr(0, 1).toUpperCase() }}{{ item.substr(1, item.length) }}
      </div>
    </div>
  </div>
</template>

<script>
import right from '../../assets/icons/right.svg'
import down from '../../assets/icons/down.svg'
export default {
  name: '',
  props: {
    menu: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    allItem: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        right,
        down
      },
      activeD: false,
      position: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendPath: function () {
      let path = window.location.pathname
      path = path.substr(1, path.length)
      if (this.allItem.indexOf(path) > -1) {
        return true
      } else {
        return false
      }
    },
  },

  created(){
    let path = window.location.pathname
    this.page = path.substr(1, path.length)
    this.verif()
  },

  methods: {
    openDrag(){
      this.activeD =! this.activeD
    },

    verif () {
      let path = window.location.pathname
      this.position = path.substr(1, path.length).toLowerCase()
    },

    selected (item) {
      this.page = item.toLowerCase()
      this.position = item.toLowerCase()
      this.$router.push('/'+item.toLowerCase())
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/sass/mixin';
.cole{
  color: #8E8EA9;
}
.cole:hover{
  color: black;
}
.change{
  color: black;
}
.menu_element_block{
  position: relative;
  cursor: pointer;
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.0168em;
  color: #8E8EA9;
  .menu_element{
    color: #8E8EA9
  }
}
.activeR{
  padding: 1rem 1rem;
  height: 48px;
  border-radius: 10px;
  color: white;
  background-color: $oho-blue-clear;
  .menu_element{
    color: white
  }
  .avtive_position{
    visibility: visible;
    background: none;
  }
  .menu_element_icon{
    path{
      fill: white!important;
    }
  }
}
.menu_element_block:hover, .activeR:hover {
  padding: 1rem 1rem;
  height: 48px;
  border-radius: 10px;
  background-color: $hover-side;
  .menu_element{
    color: #8E8EA9
  }
  .menu_element_icon{
    path{
      fill: #8E8EA9!important;
    }
  }
}
</style>
