<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal">
        <div
          class="success-container h-full pr-6"
        >
          <div
            class="titre p-4 mr-4"
            @click="yes"
          >
            Deconnexion
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupBase'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    activation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      wantToMakeOffer: false
    }
  },

  created () {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },
    yes () {
      this.$store.dispatch('logout', null)
      this.$emit('oga', false)
      this.$router.go('/login')
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 100%;
    }

    .success-container {
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre {
      font-family: $font-default;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 153%;
      color: #F44336;
      right: 80px;
      cursor: pointer;
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
      }
      .titre{
        font-size: 20px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
      }
    }
</style>
