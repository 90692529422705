<template>
  <div class="home_page h-full flex">

    <div class="w-1/5">
      <sidebar class="sid" />
    </div>
    <div class="w-4/5">
      <theHeader class="header-shadow" />
      <router-view class="men mt-32" />
    </div>
  </div>
</template>

<script>
import theHeader from '@/component/helper/header'
import sidebar from '@/component/helper/the-sidebar'

export default {
  name: 'Home',
  components: {
    theHeader,
    sidebar
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/sass/variables";
.home_page {
  width: 1440px;
  margin-left: auto;
  margin-right: auto;
  background-color: #F5F5F5;
  height: 100%;
}
.header-shadow {
  position: fixed;
  width: 65%;
}
.sid{
  width: auto;
}
@media screen and (max-width: 1440px) {
  .home_page {
    background-color: #F5F5F5;
    width: 100%;
    height: 100%;
  }
  .home_page::-webkit-scrollbar {
    display: none;
  }
  .sid{
    width: 20%;
  }
  .header-shadow {
    position: fixed;
    width: 80%;
  }
}
</style>
